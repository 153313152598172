.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
h3 {
  margin-block-start: 0;
}
.App-link {
  color: #61dafb;
}
.rdrDefinedRangesWrapper {
  display: none;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff415f;
  border-radius: 2px;
  transition: all 0.5s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff2448;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1040px) {
  ::-webkit-scrollbar {
    width: 0;
  }
}
