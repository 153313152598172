table {
  font-family: Poppins;
  border-collapse: collapse;
  width: 100%;
  color:white;
}

table td {
  border-bottom: 1px solid #313131;
  padding: 25px 4px;
  
}

table th {
  border-bottom: 2px solid #FFE324;
  padding: 15px 0;
  
}
#heading{
  display: flex;
  align-items: center;
}
table tr:nth-child(even){background-color: #131313;}

table tr:hover {
  background-color: #171717;
}

table th {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  color: white;
}